exports.components = {
  "component---src-components-templates-leaf-single-page-tsx": () => import("./../../../src/components/Templates/LeafSinglePage.tsx" /* webpackChunkName: "component---src-components-templates-leaf-single-page-tsx" */),
  "component---src-components-templates-news-single-page-tsx": () => import("./../../../src/components/Templates/NewsSinglePage.tsx" /* webpackChunkName: "component---src-components-templates-news-single-page-tsx" */),
  "component---src-components-templates-post-single-page-tsx": () => import("./../../../src/components/Templates/PostSinglePage.tsx" /* webpackChunkName: "component---src-components-templates-post-single-page-tsx" */),
  "component---src-components-templates-use-case-single-page-tsx": () => import("./../../../src/components/Templates/UseCaseSinglePage.tsx" /* webpackChunkName: "component---src-components-templates-use-case-single-page-tsx" */),
  "component---src-components-templates-youtube-single-page-tsx": () => import("./../../../src/components/Templates/YoutubeSinglePage.tsx" /* webpackChunkName: "component---src-components-templates-youtube-single-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leaves-tsx": () => import("./../../../src/pages/leaves.tsx" /* webpackChunkName: "component---src-pages-leaves-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-submissions-tsx": () => import("./../../../src/pages/submissions.tsx" /* webpackChunkName: "component---src-pages-submissions-tsx" */),
  "component---src-pages-usecases-tsx": () => import("./../../../src/pages/usecases.tsx" /* webpackChunkName: "component---src-pages-usecases-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

